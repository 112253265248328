// EN
// example simple { mykey: 'value' } | $t('mykey')
// example avec data { mykey: 'Bonjour {name}' } | $t('mykey', { name: 'toto' })
// example avec array { mykey: 'Bonjour {0} et {1} } | $t('mykey', ['toto', 'tata'])
const translation = {
    hello: 'Hello {name}',
    // SIDEBAR
    sidebar: {
        title: {
            commercial: 'Commercial',
            dashboard: 'Dashboard',
            paie: 'Paie / Cra',
            portes: 'Portés',
            mission: 'Mission',
            rh: 'RH',
            facturation: 'Billing',
            ndf: 'NDF',
            parrainage: 'Sponsorship',
            adminParams: 'Paramètres admins',
            publicHolidays: 'Public holidays'
        },
        subItems: {
            label: {
                services: 'Services',
                portes: 'Portés',
                simulation: 'Simulation',
                createMission: 'Mission creation',
                prospect: 'Prospect',
                declarePaie: 'Déclaration de paie',
                declareFacture: 'Déclaration de facturation',
                historiquePaie: 'Historique de paie',
                preparationPaie: 'Préparation paie',
                importPaie: 'Import paie',
                historyPaie: 'Pays Historic',
                company: 'Company',
                commercial: 'Commercial',
                rh: 'HR',
                adv: 'Sales administration',
                pay: 'Wage',
                ndf: 'Expenses claim',
                publicHolidays: 'Public holidays'
            }
        }
    },
    // DATATABLE
    datatable: {
        currentPageReportTemplate: 'Showing {first} to {last} of {totalRecords} {target}',
        load: 'Loading {target}. Please wait',
        notFound: 'No {target} found',
        target: {
            missions: 'missions',
            portes: 'independents',
            salaires: 'salaries',
            data: 'data',
            services: 'services',
            factures: 'invoices',
            ndfs: 'notes de frais'
        }
    },
    admin: {
        params: {
            company: {
                // titles
                generalInformationTitle: 'General informations',

                documentTypes: 'Others document types',
                socialRaisonLabel: 'Raison sociale',
                commercialNameLabel: 'Nom commercial',
                commercialStatusLabel: 'Statut',
                commercialCapitalLabel: 'Capital',
                commercialAddressLabel: 'Address',
                commercialZipCodeLabel: 'Zip code',
                commercialCityLabel: 'City',
                cityRegistrationRCSLabel: 'City registration RCS',
                commercialRcsLabel: 'RCS',
                commercialSiretLabel: 'Siret',
                representativeLabel: 'Représentative',
                professionLabel: 'Profession',
                commercialConventionLabel: 'Convention',
                commercialLogoLabel: 'Logo',
                commercialLogoImportLabel: 'Import logo',
                emailLabel: 'Email',
                phoneLabel: 'Phone number',
                registerButton: 'Enregistrer'
            },
            commercial: {
                // titles
                simulationTitle: 'Simulation :',
                commercialTitle: 'Commercial contract :',
                ikScaleTitle: 'IK Scale :',
                ikMajorationElectrique: 'Increase for electric vehicles :',
                // labels
                sourceLabel: 'How does he know us?' ,
                sourceSubLabel: 'Add options',
                managementRateLabel: 'Management rate',
                managementRateRoofLabel: 'Roof',
                managementRateRoofValueLabel: 'Value',
                transportLabel: 'Transport',
                transportCarLabel: 'Car',
                transportPublicLabel: 'Public transport',
                transportMotorBikeLabel: 'Motorbike',
                transportMotorcycleLabel: 'Motorcycle',
                financialReserveRateLabel: 'Financial reserve rate',
                financialReserveRateJuniorLabel: 'Junior',
                financialReserveRateSeniorLabel: 'Senior',
                financialReserveRateOthersLabel: 'Complement reserve',
                financialReserveSyntecRateOthersLabel: 'Reserve',
                contractTypeLabel: 'Contract',
                contractTypeCddLabel: 'CDD',
                contractTypeCdiLabel: 'CDI',

                termsAndConditionsClientParticipationLabel: 'Terms and conditions of client participation',
                paymentMethodLabel: 'Payment method',
                exceptionalExpensesLabel: 'Exceptional expenses',
                rcpInsuranceLabel: 'Rcp Insurance',
                financialGuaranteeLabel: 'Financial guarantee',
                commercialCourtLabel: 'Commercial court',

                prospectSimulationTemplateLabel: 'Prospect simulation',
                particularConditionsContractTemplate: 'Kaizen special conditions contract template',
                generalConditionsContractTemplate: 'Model general conditions of the Kaizen commercial contract',
                personalGeneralConditionsContractTemplate: 'Import your own terms and conditions',

                ikCarLabel: 'Car',
                ikMotorbikeLabel: 'Motorbike',
                ikAutomatedScaleLabel: 'Automatic scale',
            },
            rh: {
                // titles
                creditCardTitle: 'Credit card of independent :',
                restaurantTicketsTitle: 'Restaurant tickets :',
                contractTitle: 'RH contract :',
                salaryGridTitle: 'Syntec Salary grid',
                // labels
                creditCardOption: 'Credit Card',
                creditCardOptionFees: 'Card fees',
                restaurantsTicketsOption: 'Restaurants tickets',
                restaurantsTicketsAmount: 'Amount:',
                rhRestaurantsTicketsSalaryRate: 'Salary rate:',
                rhRestaurantsTicketsEmployerRate: 'Employer rate:',
                breakLetterOption: 'Renunciation letter',

                particularConditionsWorkContractTemplate: 'Kaizen special conditions work contract template',
                generalConditionsWorkContractTemplate: 'Model general conditions of the Kaizen work contract',
                personalGeneralConditionsWorkContractTemplate: 'Import your own terms and conditions',

                dialogParticularConditionsWorkContractTemplate: 'Kaizen special conditions work contract',
                dialogGeneralConditionsWorkContractTemplate: 'General conditions of the Kaizen work contract',
                dialogPersonalGeneralConditionsWorkContractTemplate: 'Your own generals terms and conditions',
                dialogAgreementConventionWorkContractTemplate: 'Your own agreement convention',

                salaryGridPosition: 'Position',
                salaryGridCoefficients: 'Coefficients',
                salaryGridMinimumBrutSalary: 'Gross minimum wage',
            },
            adv: {
                // titles
                bankAccountTitle: 'Bank account :',
                factoringAccountTitle: 'Factoring account :',
                notificationsTitle: 'Independent notifications :',
                currencyTitle: 'Currency',
                invoiceEmailTitle: 'Invoice email',
                invoiceNaming: 'Invoice naming',
                // labels
                bankAccountLabel: 'Label',
                bankAccountIban: 'IBAN',
                bankAccountRib: 'RIB',
                bankAccountBIC: 'BIC',
                addBankAccountLabel: 'Add a new bank account',

                factoringAccountLabel: 'Label',
                factoringAccountBIC: 'BIC',
                addFactoringAccountLabel: 'Add a new factoring account',

                openInvoicingNotificationDate: 'Notification date of the invoicing opening',
                openInvoicingNotificationDateByEmail: 'By email',
                openInvoicingNotificationDateByEditor: 'On Kaizen',

                foreignCurrency: 'Foreign currency',
                invoiceReceptionEmail: 'Reception email for the porting company',
            },
            payroll: {
                // titles
                restaurantTicketsTitle: 'Restaurants tickets :',
                notificationsTitle: 'Notifications :',
                socialBenefitsTitle: 'Social benefits :',
                salarySimulationBulletinTitle: 'Simulation Salary Bulletin :',

                restaurantsTicketsOption: 'Restaurants tickets',
                restaurantsTicketsAmount: 'Amount:',

                openPayrollNotificationDate: 'Notification date of the payroll opening',
                openPayrollNotificationDateByEmail: 'By email',
                openPayrollNotificationDateByEditor: 'On Kaizen',

                workTime: 'Work time',
                workingHoursOnFullTime: 'Worked hours number (full time)',

                socialSecurityMaximalCap: 'Social Security Maximal Cap',
                smic: 'SMIC Brut',
                individualMutualCoefficient: 'Individual mutual rate',
                familyMutualCoefficient: 'Family mutual rate',
                socialSecurityEmployerRate: 'Family Allowance contribution employer rate',
                csgCoefficient: 'CSG compututation rate',
                juniorCoefficient: 'Junior',
                seniorCoefficient: 'Senior',
                forfaitCoefficient: 'Fixed price',

                payrollDefinitionLabel: 'Paie definition',
                peeLabel: 'PEE',
                percoLabel: 'PERCO',
                partialUnemploymentLabel: 'Partial unemployment',
                indemnityPayedVacancy: 'Indemnity payed vacancy integrated into the salary slip',
                remoteWorkLabel: 'Remote work',
                remoteWorkFixedPriceLabel: 'Fixed price per day',
                majorMovementsOptionLabel: 'Major movements',
                majorMovementsDurationLabel: 'Duration before expire',
                lunchFixedPriceLabel: 'Lunch fixed price',
                dinnerFixedPriceLabel: 'Dinner fixed price',
                hotelFixedPriceLabel: 'Hotel fixed price'
            }
        }
    },
    // SEND DOCUMENT DIALOG
    documentEmailTitle: 'Title',
    documentEmailSubject: 'Email Object',
    documentEmailMessage: 'Email Content',
    documentEmailTitleRequired: 'Title is required',
    documentEmailSubjectRequired: 'Subject is required',
    documentEmailCancel: 'Close',
    documentEmailSendDocument: 'Send document',
    documentEmailDefaultName: 'Document',
    // CONTRACT TYPE DIALOG
    generatedSignedKey: 'Génération signé',
    generatedSimpleKey: 'Génération sans signature',
    importSimpleKey: 'Import manuel',
    // IMPORT CONTRACT DIALOG
    // CLIENT CRUD DIALOG
    clientTitle: 'Manage Client',
    contactTitle: 'Manage Contact',
    signatoriesTitle: 'Manage Signatory',
    clientForeign: 'Foreign',
    clientName: 'Name',
    clientLegalStatus: 'Legal status',
    clientCapital: 'Capital',
    clientAddress: 'Address',
    clientPostalCode: 'Code postal',
    clientCity: 'City',
    clientCountry: 'Country',
    clientCedex: 'Cedex',
    clientCityRcs: 'City RCS',
    clientSiren: 'Siren',
    clientSiret: 'Siret',
    clientIdentificationNumber: 'Identification number',
    clientRepresenting: 'Representing',
    clientQuality: 'Quality',
    contactFullName: 'Name',
    contactFirstName: 'First name',
    contactLastName: 'Last name',
    contactPosition: 'Position',
    contactPhone: 'Phone',
    contactEmail: 'Email',
    signatoryFullName: 'Name',
    signatoryEmail: 'Email',
    signatoryQuality: 'Quality',
    // Mission Creation Dialog
    adminMissionCreationTitle: 'Mission creation',
    independentMissionCreationTitle: 'Mission creation',
    createMissionButton: 'Create mission',
    // Other
    civilStatus: 'Civil status',
    male: 'Dear',
    female: 'Madam',
    firstname: 'Firstname',
    lastname: 'Lastname',
    contract: "Contract",
    clientContract: 'Contract customer',
    workContract: "Work contract",
    clientFinal: 'Final customer',
    mission: 'Mission',
    profil: 'Profil',
    documents: 'Documents',
    alone: "Alone",
    married: "Married",
    concubine: "Concubine",
    belowFive: "-5 years",
    upperFive: "+5 years",
    individual: "Individual",
    familial: "Familial",
    none: "None",
    website: "Website",
    network: "Network",
    beginDate: 'Begin date',
    endDate: 'End date',
    status: 'Statut',
    contractType: 'Contract type',
    salesManager: 'Sales manager',
    renewal: 'Renewal',
    phone: 'Phone',
    email: 'Email',
    averageDailyRate: 'Average daily rate',
    missionsContracts: 'Missions contracts',
    password: 'Password',
    forgot: 'Forgot password?',
    login: 'Login',
    changeSociety: 'Scan QR code',
    retryScan: 'Scan QR code',
    address: 'Address',
    postalCode: 'Postal code',
    city: 'City',
    country: 'Country',
    birthPlace: 'Birth place',
    birthDate: 'Birth date',
    nationality: 'Nationality',
    frenchM: 'French',
    frenchF: 'French',
    europe: 'Europe',
    europeOut: 'Hors Europe',
    validateDeclarePaie: 'Valider la déclaration de paie',
    socialSecurityNumber: 'Social security number',
    isPassportQuestion: "What type of document do you have ?",
    passportNumber: "Passeport number",
    expirationDate: "Expiration date",
    residencePermit: "Residence permit",
    countryOrigin: "Original country",
    personalSituation: "Personal situation",
    childSituation: "Child situation",
    healthIssuesQuestion: "Health issues ?",
    handicapQuestion: "Handicap ?",
    medicalVisit: "Medical visit",
    medicalVisitDate: "Medical visit date",
    endContract: 'End of contract',
    createdDate: 'Creation date',
    dailyRate: 'Daily rate',
    search: 'Search',
    export: 'Export',
    craExport: 'Download Cras',
    exportPdf: 'Export PDF',
    refundAll: 'Refund all',
    clear: 'Clear',
    validate: 'Validate',
    all: 'All',
    isPassport: 'Passport',
    isNoPassport: 'Residence permit',
    isHandicap: 'Yes',
    isNoHandicap: 'No',
    simulation: 'Simulation',
    prospect: 'Prospect',
    view: 'View {target}',
    save: 'Save',
    send: 'Send',
    job: 'Job',
    total: 'Total',
    NDF: 'Expense report',
    year: 'Year',
    month: 'Month',
    hours: 'Hours',
    paidHT: 'Paid (excl. VAT)',
    paidNet: 'Paid (incl. VAT)',
    managementFee: 'Management fee',
    managementFeeRate: 'Management fee rate',
    managementRateRoofLabel: 'Roof',
    otherMissions: 'On-call and other missions',
    acompte: 'Down payment',
    cotisationsPatronales: 'Employer contribution',
    totalCotisations: 'Total contributions',
    brut: 'Gross',
    fraisAnnexes: 'Additional costs',
    abonnementSport: 'Sports membership',
    loisirs: 'Leisure/culture',
    chequesCadeau: 'Gift vouchers',
    chequesCulture: 'Culture vouchers',
    cesu: 'CESU',
    pee: 'PEE',
    perco: 'PERCO',
    envoi: 'Envoi pour les chèques et ouverture PEE',
    fraisRefactures: 'Mission expenses rebilled',
    tjm: 'Average daily rate',
    nbJoursCra: 'Activity report days',
    nomFichier: 'Filename',
    taille: 'Size',
    date: 'Date',
    beneficiare: 'Recipient',
    libelle: 'Name',
    category: 'Category',
    nbDepenses: 'Number of expenses',
    tvaRecuperee: 'VAT retrieved',
    nature: 'Nature',
    iban: 'IBAN',
    rectoIdentityCard: "Recto identity card",
    versoIdentityCard: "Verso identity card",
    proofResidence: "Proof residence",
    ibanNdf: "IBAN Ndf",
    ibanSalary: "IBAN Salary",
    ibanNdfInput: "IBAN-Expense reports",
    ibanSalaryInput: "IBAN-Salary",
    cpamCertification: "CPAM certification",
    mutualProof: "Mutual proof",
    mutualCertification: "Mutual certification",
    curriculumVitae: "Curriculum vitae",
    agefipCertification: "AGEFIP certification",
    passport: "Passport",
    residentPermit: "Resident permit",
    other: "Others",
    dpae: "DPAE",
    skillsDetails: "Skills details",
    jobPosition: "Post",
    montant: 'Amount',
    type: 'Type',
    etat: 'State',
    entreprises: 'Companies',
    references: 'Identification',
    missions: 'Missions',
    syntheseGenerale: 'General overview',
    syntheseNDF: 'Expense report / Social devices / Supplier purchases overview',
    syntheseTraitement: 'In process',
    syntheseCA: 'Revenues overview',
    attenteReglement: 'Pending settlement',
    cumulReserve: 'Reserve pool',
    activitesAttente: 'Pending activities',
    avance: 'Advanced',
    facturation: 'Billing',
    gestion: 'Management',
    dispositifsSociaux: 'Social devices',
    both: "Both",
    salary: "Salary",
    salaires: 'Salaries',
    baseSalary: "Base salary",
    contractualisation: "Contract",
    workContractDocumentName: 'Work Contract',
    contractDocumentName: 'Contract',
    contractAmendmentDocumentName: 'Contract Amendment',
    saveDraftMission: 'Save',
    createDraftMission: 'Create new mission',
    columnWorkedDaysOnWorkableDays: 'NB.WORKED / NB.WORKABLE',
    // VALIDATIONS
    validations: {
        // CLIENT FORM
        nameClientRequired: 'Client name is required',
        legalStatusClientRequired: 'Client status is required',
        capitalClientMinValue: 'Client capital has to bee superior to zero',
        capitalClientRequired: 'Client capital is required',
        addressClientRequired: 'Client address is required',
        postalCodeClientRequired: 'Client postal code is required',
        cityClientRequired: 'Client city is required',
        sirenClientRequired: 'Client siren number is required',
        rcsCityClientRequired: 'Client city of the RCS number is required',
        siretClientRequired: 'Client siret is required',
        identificationNumberClientRequired: 'Client identification number is required',
        countryClientRequired: 'Client country is required',
        representativeClientRequired: 'Client representative is required',
        qualityClientRequired: 'Client representative quality is required',

        // IBAN PROSPECT
        ibanSalaryNotValidated: `Salary iban is invalid`,
        ibanNdfNotValidated: `Expense reports is invalid`
    },
    // ERRORS
    errors: {
        defaultError: 'Une erreur s\'est produite',
        defaultLoginError: 'Login to your account has failed',
        accountNotFound: 'Account not found',
        invalidEmail: 'L\'email renseigné est invalide',
        invalidSocSecurityCode: 'Le numéro de sécurité sociale renseigné est invalide',
        fieldRequired: 'All fields {symbole} required',
        invalidToken: 'Invalid token',
        deleteLastDueDate: 'Vous ne pouvez pas supprimer la dernière échéance',
        importPays: 'An error has occurred at the pays import',
        'importPays-personalNumber-missing': `Missing some include of personal number from pays import`,
        'importPays-RECORD_NOT_UNIQUE': `Some personal number are duplicates, those personal number must be uniques`,
        sendSimulation: 'An error has occurred when sending simulation',
        sendSimulationPaySlipInvalidate: 'Une erreur s\'est produite à la validation du bulletin de salaire pour cette siumulation, nous vous conseillons d\'augmenter le CH facturé ou le CH payé',
        adminCommercialFormUnselectGeneralConditionTemplate: 'Choose one type of general conditions',
        adminCommercialFormUnUploadedFile: 'You have to import your own terms and conditions to be able to register',
        adminCommercialFormUnSelectedContract: 'Choose at least one contract type',
        adminCommercialFormTauxReserveFinanciereUnset: 'Un taux de réserve financière est manquant',
        adminAdvFormBankAccountUnset: 'Un compte bancaire n\'est pas complet',
        adminAdvFormBillingAccountUnset: 'Un compte d\'affacturage n\'est pas complet',
        adminAdvFormDateNotificationUnset: 'Veuillez sélectionner une régularité',
        adminAdvInvoiceNaming: 'Veuillez renseigner une nommenclature de facture et un index de départ',
        adminCommercialFormManagementRoofAmountUnset: 'La valeur du plafond est manquante',
        adminRhFormUnUploadedFile: 'Importez vos conditions générales pour enregistrer',
        adminRhFormCreditCardFeesUnset: 'Les frais de carte sont manquants',
        adminRhFormRestaurantsTicketsAmountUnset: 'Le nombre de tickets restaurants est manquant',
        adminRhFormSalaryGridUnvalid: 'Les lignes de la grille salariale doivent être complétées et ne pas contenir de doublons',
        contractNotImported: 'You have to import the contract to validate',
        invalidHoraire: 'Hour number invalid (1h to 24h max)',
        sendRestaurantTicketsRenunciationLetter: 'Error on sending the letter',
        paySlipDeclarationNotImport: `The salary slip of this declaration is missing`,
        craClientNotAvailable: `The client CRA is missing`,
        craIndependentNotAvailable: `The independent CRA is not available`,
        payDeclarationNotImport: `Pay data are missing`,
        cancelContractSignature: `The pending signature cancellation of the current contract failed`,
        cancelSignatureConflict: `This signature request is currently being processed. Please wait`,
        cancelSignatureUnauthorized: `You cannot cancel signature with your current role`,

        // directus-code-error
        INVALID_CREDENTIALS: 'Invalid credentials',

        // request errors
        defaultErrorKey: 'This field is errored', // no specific details
        clientSiretNotUnique: 'This siret is already used',
        clientIdentificationNumberNotUnique: 'This identification number is already used',
        contactEmailNotUnique: 'This email is already used',
        publicHolidaysGetError: 'Une erreur s\'est produite lors de la récupération des jours fériés',
        publicHolidayGetOneError: 'Une erreur s\'est produite lors de la récupération des jours fériés',
        publicHolidayDeleteError: 'Une erreur s\'est produite lors de la suppression des jours fériés',
        publicHolidaySaveError: 'Une erreur s\'est produite lors de la sauvegarde des jours fériés'
    },
    // SUCCESS
    success: {
        saveDocument: 'Document save',
        deleteDocument: 'Document deleted',
        saveSimulation: 'Simulation save',
        sendSimulation: 'Simulation send',
        savePay: 'Paie sauvegardée',
        saveDeclarePay: 'Déclaration de paie sauvegardée',
        declarePaieValidate: 'Déclaration de paie validée',
        saveDeclareFacturation: 'Déclaration de facturation sauvegardée',
        declareFacturationValidate: 'Déclaration de facturation validée',
        saveMission: 'Mission save',
        saveProfil: 'Profil save',
        importPays: 'Import success',
        forcePaysDeclaration: 'Pays declared',
        sendRestaurantTicketsRenunciationLetter: 'The letter has been sent',
        publicHolidaySave: `Les jours fériés de l'année {year} ont été sauvegardés`,
        publicHolidayDelete: `Les jours fériés de l'année {year} ont été supprimés`,
        publicHolidayCreate: `Les jours fériés de l'année {year} ont été créés`,
        cancelContractSignature: `The pending signature cancellation of the current contract is done`,
        reportDone: `Relance envoyée`,
    },
    // WARNING
    warning: {
        invalidStackHoraire: 'Hour number cumulated exceeded (1h to 24h max)',
        invalidStackDay: 'Day number cumulated exceeded (1 max)',
    },
    // INFO
    info: {},
};

export default translation;
