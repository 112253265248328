import axios from '@/plugins/axios'
import {formatFromApi, formatToApi} from "@/utils/DeclarationFacturationUtil";

export default {
    namespaced: true,

    state: {
        declarationFacturation: undefined,
        declarationsFacturation: [],
        missionPricingByTimesheet: [],
    },
    getters: {
        getDeclarationFacturation (state) {
            return state.declarationFacturation
        },
        getDeclarationsFacturation (state) {
            return state.declarationsFacturation
        },
    },
    mutations: {
        UPDATE_DECLARATION_FACTURATION(state, value) {
            let index = state.declarationsFacturation.findIndex((df) => { return df.id === value.id })
            if (index !== -1) {
                const previousValue = state.declarationsFacturation[index]
                state.declarationsFacturation.splice(index, 1, {
                    ...previousValue,
                    ...value
                })
            }
        },
        DELETE_SERVICE_FROM_DECLARATION_FACTURATION (state, data) {
            let serviceIndex = -1
            if (data.declarationFacturationIndex || data.declarationFacturationIndex === 0) {
                data.ids.forEach((serviceId) => {
                    serviceIndex = state.declarationsFacturation[data.declarationFacturationIndex].services.findIndex(service => service.id === serviceId)
                    if (serviceIndex !== -1) {
                        state.declarationsFacturation[data.declarationFacturationIndex].services.splice(serviceIndex, 1)
                    }
                })
            }
        },
        SET_DECLARATIONS_FACTURATION(state, value) {
            state.declarationsFacturation = value
        },
        SET_DECLARATION_FACTURATION(state, value) {
            state.declarationFacturation = value
        },
        SET_MISSION_PRICING_BY_TIMESHEET(state, data) {
            state.missionPricingByTimesheet = data
        },
        ADD_MISSION_PRICING_BY_TIMESHEET(state, data) {
            state.missionPricingByTimesheet.push(data)
        },
        ADD_SUB_MISSION_PRICING_BY_TIMESHEET(state, data) {
            state.missionPricingByTimesheet[data.missionIndex].push(data.negotiatedRates)
        }
    },
    actions: {
        async getAllDeclarationFacturationByIndep({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.api.get('/declarationFacturation/independent/' + data.idIndep + '/' + data.year + '/' + data.month).then((res) => {
                    let results = res.data.map((decla) => formatFromApi(decla))
                    commit('SET_DECLARATIONS_FACTURATION', results)
                    resolve(results)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        async getAllDeclarationFacturation({ commit }) {
            return new Promise((resolve, reject) => {
                axios.api.get('/declarationFacturation').then((res) => {
                    let results = res.data.map((decla) => formatFromApi(decla))
                    commit('SET_DECLARATIONS_FACTURATION', results)
                    resolve(results)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        async getLastInvalidateFacturation({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.api.get('/declarationFacturation/invalidates/' + data.independentId + '/' + data.month + '/' + data.year).then((res) => {
                    let results = res.data.map((decla) => formatFromApi(decla))
                    commit('SET_DECLARATIONS_FACTURATION', results)
                    resolve(results)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        async createDeclarationFacturation({ commit }, data) {
            data = formatToApi(data)
            return new Promise((resolve, reject) => {
                axios.api.post('/declarationFacturation/', data).then((res) => {
                    res.data = formatFromApi(res.data)
                    commit('SET_DECLARATION_FACTURATION', res.data)
                    resolve(res.data)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        // eslint-disable-next-line
        async updateDeclarationFacturation({ commit }, data) {
            data = formatToApi(data)
            return new Promise((resolve, reject) => {
                axios.api.put('/declarationFacturation/' + data.id, data).then((res) => {
                    res.data = formatFromApi(res.data)
                    commit('UPDATE_DECLARATION_FACTURATION', res.data)
                    resolve(res.data)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        async getMissingInvoiceDeclaration ({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.api.get(`/declarationFacturation/missing-invoice-declaration/${data.month}/${data.year}`)
                    .then((res) => {
                        resolve(res.data)
                    }).catch((e) => {
                        reject(e)
                    })
            })
        },
        // eslint-disable-next-line no-unused-vars
        async notifyIndependent ({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.api.post(`/declarationFacturation/notify-independent/${data.independentId}/${data.invoiceDeclarationId}`)
                    .then((res) => {
                        const result = formatFromApi(res.data)
                        commit('UPDATE_DECLARATION_FACTURATION', result)
                        resolve(result)
                    }).catch((e) => {
                        reject(e)
                    })
            })
        },
        // eslint-disable-next-line
        async downloadCras({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.api.post(`/declarationFacturation/multi-download/${data.independentId}`).then(async (res) => {
                    resolve(res)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        setDeclarationFacturation ({ commit }, declarationsFacturation) {
            commit('UPDATE_DECLARATION_FACTURATION', declarationsFacturation)
        },
        setDeclarationsFacturation ({ commit }, declarationsFacturation) {
            commit('SET_DECLARATIONS_FACTURATION', declarationsFacturation)
        },
        setMissionPricingByTimesheet (store, data) {
            store.commit('SET_MISSION_PRICING_BY_TIMESHEET', data)
        },
        addMissionPricingByTimesheet (store, data) {
            store.commit('ADD_MISSION_PRICING_BY_TIMESHEET', data)
        },
        addSubMissionPricingByTimesheet (store, data) {
            store.commit('ADD_SUB_MISSION_PRICING_BY_TIMESHEET', data)
        }
    },
}
