// FR
// example simple { mykey: 'value' } | $t('mykey')
// example avec data { mykey: 'Bonjour {name}' } | $t('mykey', { name: 'toto' })
// example avec array { mykey: 'Bonjour {0} et {1} } | $t('mykey', ['toto', 'tata'])
const translation = {
    hello: 'Bonjour {name}',
    // SIDEBAR
    sidebar: {
        title: {
            commercial: 'Commercial',
            dashboard: 'Tableau de bord',
            paie: 'Paie / Cra',
            portes: 'Portés',
            mission: 'Mission',
            rh: 'Ressources humaines',
            facturation: 'Facturation',
            ndf: 'Notes de frais',
            parrainage: 'Parrainage',
            adv: 'Factures',
            adminParams: 'Paramétrage',
            publicHolidays: 'Jours fériés'
        },
        subItems: {
            label: {
                adminUsersManagement: 'Gestion utilisateurs',
                portes: 'Portés',
                simulation: 'Simulation',
                createMission: 'Création de mission',
                // amendment: 'Avenant',
                prospect: 'Prospects',
                declarePaie: 'Déclaration de paie',
                declareFacture: 'Déclaration de facturation',
                historiquePaie: 'Historique de paie',
                preparationPaie: 'Préparation paie',
                ndfAValider: 'Notes de frais à valider',
                ndfRemboursements: 'Notes remboursements',
                ff: 'Factures fournisseurs',
                ffValidees: 'Factures fournisseurs validées',
                importPaie: 'Import paie',
                services: 'Services',
                factureReglements: 'Facture règlements',
                factureAttente: 'Facture en attente',
                NDF: 'Notes de frais',
                NDFValider: 'Notes de frais à valider',
                NDFRemboursements: 'Notes remboursements',
                FF: 'Factures fournisseurs',
                FFValidees: 'Factures fournisseurs validées',
                dispositifsSociaux: 'Dispositifs sociaux',
                indemnitesKilometriques: 'Indemnités kilométriques',
                informationsPersonnelles: 'Informations personnelles',
                documents: 'Documents',
                sortie: 'Sortie',
                historyPaie: 'Historique de Paie',
                company: 'Entreprise',
                commercial: 'Commercial',
                rh: 'RH',
                adv: 'ADV',
                pay: 'Paie',
                ndf: 'NDF',
                publicHolidays: 'Jours fériés'
            }
        }
    },
    // DATATABLE
    datatable: {
        currentPageReportTemplate: 'Affichage de {first} à {last} sur {totalRecords} {target}',
        load: 'Chargement des {target}. Veuillez patientez',
        notFound: 'Pas de {target} trouvées',
        target: {
            missions: 'missions',
            pays: 'paies',
            salaires: 'salaires',
            data: 'données',
            portes: 'portés',
            depenses: 'dépenses',
            ndf: 'notes de frais',
            factures: 'factures',
            services: 'services',
            users: 'Utilisateurs'
        }
    },
    admin: {
        params: {
            company: {
                generalInformationTitle: 'Informations générales',

                documentTypes: 'Autres types de document',
                socialRaisonLabel: 'Raison sociale',
                nameLabel: 'Nom commercial',
                statusLabel: 'Statut juridique',
                capitalLabel: 'Capital',
                addressLabel: 'Adresse',
                zipCodeLabel: 'Code postal',
                cityLabel: 'Ville',
                cityRegistrationRCSLabel: 'Ville d\'immatriculation RCS',
                rcsLabel: 'RCS',
                siretLabel: 'Siret',
                representativeLabel: 'Représentant',
                professionLabel: 'Métier',
                conventionLabel: 'Convention',
                logoLabel: 'Logo',
                logoImportLabel: 'Importer mon logo',
                emailLabel: 'Email',
                phoneLabel: 'Numéro de téléphone',
                registerButton: 'Enregistrer',
                requiredField: 'Ce champ est requis'
            },
            commercial: {
                // titles
                simulationTitle: 'Simulation :',
                commercialTitle: 'Contrat commercial :',
                ikScaleTitle: 'Barèmes IK :',
                ikMajorationElectrique: 'Majoration pour les véhicules électriques :',
                // labels
                sourceLabel: 'Comment nous connait-il ?',
                sourceSubLabel: 'Ajouter des options',
                managementRateLabel: 'Tarif de gestion',
                managementRateRoofLabel: 'Plafond',
                managementRateRoofValueLabel: 'Valeur',
                transportLabel: 'Transport',
                transportCarLabel: 'Voiture',
                transportPublicLabel: 'Transports en commun',
                transportMotorBikeLabel: 'Moto',
                transportMotorcycleLabel: 'Cyclomoteur',
                financialReserveRateLabel: 'Taux de réserve financière',
                financialReserveRateJuniorLabel: 'Junior',
                financialReserveRateSeniorLabel: 'Senior',
                financialReserveRateFixedPriceLabel: 'Forfait',
                financialReserveRateOthersLabel: 'Complément de réserve',
                financialReserveSyntecRateOthersLabel: 'Réserve',
                contractTypeLabel: 'Contrat',
                contractTypeCddLabel: 'CDD',
                contractTypeCdiLabel: 'CDI',

                termsAndConditionsClientParticipationLabel: 'Modalités de participation du client',
                paymentMethodLabel: 'Moyen de paiement',
                exceptionalExpensesLabel: 'Frais exceptionnels',
                rcpInsuranceLabel: 'Assurance RCP',
                financialGuaranteeLabel: 'Garantie financière',
                commercialCourtLabel: 'Tribunal de commerce',

                prospectSimulationTemplateLabel: 'Simulation prospect',
                particularConditionsContractTemplate: 'Modèle de contrat des conditions particulières de Kaizen',
                generalConditionsContractTemplate: 'Modèle de conditions générales de contrat commercial Kaizen',
                personalGeneralConditionsContractTemplate: 'Importer ses propres conditions générales',

                dialogParticularsConditionsContractTemplate: 'Conditions particulière - Contrat commerciale',
                dialogGeneralsConditionsContractTemplate: 'Conditions générales - Contrat commerciale',
                dialogOwnGeneralsConditionsContractTemplate: 'Aperçu de vos conditions général - Contrat commerciale',
                openTemplateLabel: 'Voir',

                ikCarLabel: 'Voiture',
                ikMotorbikeLabel: 'Moto',
                ikAutomatedScaleLabel: 'Barème automatique',
            },
            rh: {
                // titles
                creditCardTitle: 'Carte bancaire porté :',
                restaurantTicketsTitle: 'Tickets restaurants :',
                contractTitle: 'Contrat RH :',
                salaryGridTitle: 'Grille salarial Syntec',
                // labels
                creditCardOption: 'Carte bancaire',
                creditCardOptionFees: 'Frais de carte',
                restaurantsTicketsOption: 'Tickets restaurants',
                restaurantsTicketsAmount: 'Montant:',
                rhRestaurantsTicketsSalaryRate: 'Taux salarial:',
                rhRestaurantsTicketsEmployerRate: 'Taux patronal:',

                breakLetterOption: 'Lettre de renonciation',

                particularConditionsWorkContractTemplate: 'Modèle des conditions patrticulères du contrat de travail de portage Kaizen',
                generalConditionsWorkContractTemplate: 'Modèle de conditions générales de contrat de portage Kaizen',
                personalGeneralConditionsWorkContractTemplate: 'Importer ses propres conditions générales',

                dialogParticularConditionsWorkContractTemplate: 'Conditions particulière - Contrat de travail',
                dialogGeneralConditionsWorkContractTemplate: 'Conditions générales - Contrat de travail',
                dialogPersonalGeneralConditionsWorkContractTemplate: 'Aperçu de vos conditions général - Contrat de travail',
                dialogAgreementConventionWorkContractTemplate: 'Aperçu de votre convention d\'adhésion - Contrat de travail',

                addConventionPortageSalarial: "Ajouter la convention d'adhésion au portage salarial",
                boxForesightTitle: "Caisse de retraite et de prévoyance",
                retirement: "Retraite",
                foresight: "Prévoyance",

                salaryGridPosition: 'Position',
                salaryGridCoefficients: 'Coefficients',
                salaryGridMinimumBrutSalary: 'Salaire minimum brut',
            },
            adv: {
                // titles
                bankAccountTitle: 'Compte bancaire :',
                factoringAccountTitle: 'Compte d\'affacturage :',
                notificationsTitle: 'Notifications pour le porté :',
                currencyTitle: 'Devise',
                invoiceEmailTitle: 'Email de facture',
                invoiceNaming: 'Nomenclature de facture',
                avoirNaming: 'Nomenclature d\'avoir',
                // labels
                bankAccountLabel: 'Libellé',
                bankAccountIban: 'IBAN',
                bankAccountRib: 'RIB',
                bankAccountBIC: 'BIC',
                addBankAccountLabel: 'Ajouter un compte bancaire',

                factoringAccountLabel: 'Libellé',
                factoringAccountBIC: 'BIC',
                addFactoringAccountLabel: 'Ajouter un compte d\'affacturage',

                openInvoicingNotificationDate: 'Date de notification d\'ouverture de facturation',
                openInvoicingNotificationDateByEmail: 'Par email',
                openInvoicingNotificationDateByEditor: 'Sur Kaizen',

                foreignCurrency: 'Devise étrangère',
                invoiceReceptionEmail: 'Email de réception pour l\'entreprise de portage',
                invoiceRecipientsEmail: 'Emails des destinataires en Cc',

                startIndex: 'Index de départ',
                format: 'Format',
                year: 'Année',
                month: 'Mois',
                independentLastName: 'Nom de famille du porté',
                clientName: 'Nom du client',
                reference: 'Référence',
                invoice_number: 'N° Facture',
            },
            payroll: {
                // titles
                restaurantTicketsTitle: 'Tickets restaurants :',
                notificationsTitle: 'Notifications :',
                socialBenefitsTitle: 'Avantages sociaux :',
                salarySimulationBulletinTitle: 'Bulletin de salaire de simulation :',

                restaurantsTicketsOption: 'Tickets restaurants',
                restaurantsTicketsAmount: 'Montant:',

                openPayrollNotificationDate: 'Date de notification d\'ouverture de paie',
                openPayrollNotificationDateByEmail: 'Par email',
                openPayrollNotificationDateByEditor: 'Sur Kaizen',

                workTime: 'Temps de travail',
                workingHoursOnFullTime: 'Nombre d\'heures travaillées (temps plein)',

                socialSecurityMaximalCap: 'Plafond Securité Sociale',
                smic: 'SMIC Brut',
                individualMutualCoefficient: 'Taux de la mutuelle individuelle',
                familyMutualCoefficient: 'Taux de la mutuelle familliale',
                socialSecurityEmployerRate: 'Taux patronale de la cotisation Allocation Familiale',
                csgCoefficient: 'Taux sur le brut pour le calcul de la CSG',
                juniorCoefficient: 'Junior',
                seniorCoefficient: 'Senior',
                forfaitCoefficient: 'Forfaitaire',

                payrollDefinitionLabel: 'Définition de la paie',
                peeLabel: 'PEE',
                percoLabel: 'PERCO',
                partialUnemploymentLabel: 'Chômage partiel',
                indemnityPayedVacancy: 'Indemnités de congés payés intégrées dans le bulletin de salaire',
                remoteWorkLabel: 'Télétravail',
                remoteWorkFixedPriceLabel: 'Forfait par jour',
                majorMovementsOptionLabel: 'Forfaits grands déplacements',
                majorMovementsDurationLabel: 'Durée avant expiration',
                lunchFixedPriceLabel: 'Forfait repas midi',
                dinnerFixedPriceLabel: 'Forfait repas soir',
                hotelFixedPriceLabel: 'Forfait hôtel'
            }
        }
    },
    // SEND DOCUMENT DIALOG
    documentEmailTitle: 'Titre',
    documentEmailSubject: 'Objet de l\'email',
    documentEmailMessage: 'Corps de l\'email',
    documentEmailTitleRequired: 'Le titre est requis',
    documentEmailSubjectRequired: 'Le sujet est requis',
    documentEmailCancel: 'Fermer',
    documentEmailSendDocument: 'Envoyer le document',
    documentEmailDefaultName: 'Document',
    // CONTRACT TYPE DIALOG
    generatedSignedKey: 'Génération signé',
    generatedSimpleKey: 'Génération sans signature',
    importSimpleKey: 'Import manuel',
    // CLIENT CRUD DIALOG
    clientTitle: 'Gestion Client',
    contactTitle: 'Gestion Contact',
    signatoriesTitle: 'Gestion Signataire',
    clientForeign: 'Etranger',
    clientName: 'Nom',
    clientLegalStatus: 'Statut juridique',
    clientCapital: 'Capital',
    clientAddress: 'Adresse',
    clientPostalCode: 'Code postal',
    clientCity: 'Ville',
    clientCountry: 'Pays',
    clientCedex: 'Cedex',
    clientCityRcs: 'Ville RCS',
    clientSiren: 'Siren',
    clientSiret: 'Siren ou Siret',
    clientIdentificationNumber: 'Numéro d\'identification',
    clientRepresenting: 'Représentant',
    clientQuality: 'Qualité',
    contactFullName: 'Nom',
    contactFirstName: 'Prénom',
    contactLastName: 'Nom',
    contactPosition: 'Qualité',
    contactPhone: 'Téléphone',
    contactEmail: 'Email',
    signatoryFullName: 'Nom',
    signatoryEmail: 'Email',
    signatoryQuality: 'Qualité',
    // Mission Creation Dialog
    adminMissionCreationTitle: 'Création de mission',
    independentMissionCreationTitle: 'Demande de création de mission',
    createMissionButton: 'Créer la mission',
    // Other
    civilStatus: 'Etat civil',
    male: 'Monsieur',
    female: 'Madame',
    firstname: 'Prénom',
    lastname: 'Nom',
    last_access: 'Dernière connexion',
    role: 'Rôle',
    contract: "Contrat",
    clientContract: 'Client contrat',
    workContract: "Contrat de travail",
    clientFinal: 'Client final',
    mission: 'Mission',
    profil: 'Profil',
    documents: 'Documents',
    alone: "Célibataire",
    married: "Marié",
    concubine: "Concubine",
    belowFive: "-5 ans",
    upperFive: "+5 ans",
    individual: "Individuelle",
    familial: "Familiale",
    none: "Aucune",
    website: "Site internet",
    network: "Réseau",
    beginDate: 'Date de début',
    endDate: 'Date de fin',
    status: 'Statut',
    contractType: 'Type de contrat',
    salesManager: 'Chargé commercial',
    renewal: 'Renouvel\u00c9',
    phone: 'Téléphone',
    email: 'Email',
    averageDailyRate: 'Tarif journalier moyen',
    missionsContracts: 'Contrats de prestations',
    password: 'Mot de passe',
    passwordConfirm: 'Confirmation',
    changePassword: 'Changer le mot de passe',
    forgot: 'Mot de passe oublié?',
    login: 'Connexion',
    changeSociety: 'Scanner un QR code',
    retryScan: 'Scanner QR code',
    resetPassword: 'Réinitialiser le mot de passe',
    postalCode: "Code postal",
    address: 'Adresse postale',
    city: 'Ville',
    country: 'Pays',
    birthPlace: 'Lieu de naissance',
    birthDate: 'Date de naissance',
    nationality: 'Nationalité',
    frenchM: 'Français',
    frenchF: 'Française',
    europe: 'Europe',
    europeOut: 'Hors Europe',
    socialSecurityNumber: 'Numéro de sécurité sociale',
    isPassportQuestion: "Quel type de document avez-vous ?",
    passportNumber: "Numéro de passeport",
    expirationDate: "Date de fin de validité",
    residencePermit: "Numéro de titre de séjour",
    countryOrigin: "Pays d'origine",
    personalSituation: "Situation personnelle",
    childSituation: "Enfant à charge",
    healthIssuesQuestion: "Problème de santé ?",
    handicapQuestion: "Handicap ?",
    medicalVisit: "Visite médicale",
    medicalVisitDate: "Date visite médicale",
    endContract: 'Fin de contrat',
    createdDate: 'Date de création',
    dailyRate: 'Tarif journalier',
    search: 'Rechercher',
    export: 'Exporter',
    craExport: 'Télécharger cras',
    exportPdf: 'Exporter les PDF',
    refundAll: 'Tout rembourser',
    clear: 'Effacer',
    validate: 'Valider',
    all: 'Tous',
    price: 'Prix',
    isPassport: 'Passport',
    isNoPassport: 'Titre de séjour',
    isHandicap: 'Oui',
    isNoHandicap: 'Non',
    simulation: 'Simulation',
    prospect: 'Prospect',
    distanceTrajet: 'Distance du trajet',
    distanceTotale: 'Distance totale',
    nombreTrajets: 'Nombre de trajets',
    vehicle: 'Véhicule',
    bareme: 'Barème',
    view: 'Voir la {target}',
    save: 'Enregistrer',
    send: 'Envoyer',
    ndfToValidate: 'Notes de frais à valider',
    validateDeclarePaie: 'Valider la déclaration de paie',
    job: 'Métier',
    total: 'Total',
    NDF: 'NDF',
    year: 'Année',
    month: 'Mois',
    hours: 'Heures',
    paidHT: 'Facturé (HT)',
    paidNet: 'Net à payer',
    managementFee: 'Tarif de gestion',
    managementFeeRate: 'Taux de gestion',
    managementRateRoofLabel: 'Plafond',
    otherMissions: 'Astreinte ou autres missions',
    acompte: 'Acompte',
    cotisationsPatronales: 'Cotisations patronales',
    totalCotisations: 'Total des cotisations sociales',
    brut: 'BRUT',
    fraisAnnexes: 'Frais annexes',
    abonnementSport: 'Abonnement sport',
    loisirs: 'Loisirs/culture',
    chequesCadeau: 'Chèques cadeau',
    chequesCulture: 'Chèques culture',
    cesu: 'CESU',
    pee: 'PEE',
    perco: 'PERCO',
    envoi: 'Envoi pour les chèques et ouverture PEE',
    fraisRefactures: 'Frais de missions refacturés',
    tjm: 'TJM',
    nbJoursCra: 'Nb de jours CRA',
    nomFichier: 'Nom du fichier',
    taille: 'Taille',
    date: 'Date',
    beneficiare: 'Bénéficiaire',
    libelle: 'Libellé',
    category: 'Catégorie',
    nbDepenses: 'Nombre de dépenses',
    tvaRecuperee: 'TVA récupérée',
    remboursement: 'Remboursement',
    dateValidation: 'Date de validation',
    dateRemboursement: 'Date de remboursement',
    refacturable: 'Refacturable',
    nature: 'Nature',
    iban: 'IBAN',
    rectoIdentityCard: "Carte d'identité recto",
    versoIdentityCard: "Carte d'identité verso",
    proofResidence: "Justificatif de domicile",
    ibanNdf: "IBAN Note de frais",
    ibanSalary: "IBAN Salaire",
    ibanNdfInput: "IBAN-Note de frais",
    ibanSalaryInput: "IBAN-Salaire",
    cpamCertification: "Attestation CPAM",
    mutualProof: "Attestation mutuelle",
    mutualCertification: "Justificatif mutuelle",
    curriculumVitae: "Curriculum vitae",
    agefipCertification: "Attestation AGEFIP",
    passport: "Passport",
    residentPermit: "Titre de séjour",
    other: "Autres",
    dpae: "DPAE",
    skillsDetails: "Compétences et qualifications",
    jobPosition: "Poste et métier",
    montant: 'Montant',
    type: 'Type',
    etat: 'Etat',
    entreprises: 'Entreprises',
    references: 'Références',
    missions: 'Missions',
    syntheseGenerale: 'Synthèse générale',
    syntheseNDF: 'Synthèse notes de frais / Dispositifs sociaux / Achats fournisseurs',
    syntheseTraitement: 'En cours de traitement',
    syntheseCA: 'Synthèse chiffre d\'affaires',
    attenteReglement: 'Prévisionnel',
    cumulReserve: 'Cumul de réserve',
    activitesAttente: 'Activités en attente',
    avance: 'Disponible',
    facturation: 'Facturation',
    gestion: 'Gestion',
    indemnitesKilometriques: 'Indemnités kilométriques',
    dispositifsSociaux: 'Dispositifs sociaux',
    both: 'Les deux',
    salary: "Salaire",
    salaires: 'Salaires',
    reference: 'Référence',
    service: 'Service',
    client: 'Client',
    settlementDelay: 'Délai de paiement',
    montantHT: 'Montant HT',
    baseSalary: "Salaire de base",
    invoice: "Facture",
    numeroFacture: 'N° Facture',
    numeroNDF: 'N° Note de frais',
    paid: 'Réglé',
    delaiReglement: 'Délai',
    echeance: 'Échéance',
    retard: 'Retard',
    paidAmount: 'Réglé',
    bank: 'Banque',
    ttc: 'TTC',
    cagnotte: 'Cagnotte',
    ht: 'HT',
    tva: 'TVA',
    deduire: 'A déduire',
    comment: 'Commentaire',
    contractualisation: "Générer le contrat",
    workContractDocumentName: 'Contrat de travail',
    contractDocumentName: 'Contrat',
    contractAmendmentDocumentName: 'Avenant au Contrat',
    saveDraftMission: 'Enregistrer',
    createDraftMission: 'Créer mission',
    columnWorkedDaysOnWorkableDays: 'NB.TRAVAILLÉS / NB.OUVRÉS',
    // VALIDATIONS
    validations: {
        // CLIENT FORM
        nameClientRequired: 'Le nom du client est requis',
        legalStatusClientRequired: 'Le statut juridique du client est requis',
        capitalClientMinValue: 'Le capital d`\'un client doit être supérieur à zéro',
        capitalClientRequired: 'Le capital du client est requis',
        addressClientRequired: 'L\'adresse du client est requise',
        postalCodeClientRequired: 'Le code postal du client est requis',
        cityClientRequired: 'La ville du client est requise',
        sirenClientRequired: 'Le numéro siren du client est requis',
        rcsCityClientRequired: 'La ville du numéro RCS du client est requise',
        siretClientRequired: 'Le siret du client est requis',
        identificationNumberClientRequired: 'Le numéro d`\'identifiant est requis',
        countryClientRequired: 'Le pays est requis',
        representativeClientRequired: 'Le représentant du client est requis',
        qualityClientRequired: 'La qualité du client est requise',

        // IBAN PROSPECT
        ibanSalaryNotValidated: `L'iban du salaire est invalide`,
        ibanNdfNotValidated: `L'iban des notes de frais est invalide`
    },
    // ERRORS
    errors: {
        defaultLoginError: 'La connexion à votre compte utilisateur à échouer',
        accountNotFound: 'Ce compte est introuvable',
        invalidEmail: 'L\'email renseigné est invalide',
        invalidSocSecurityCode: 'Le numéro de sécurité sociale renseigné est invalide',
        fieldRequired: 'Tous les champs {symbole} sont requis',
        invalidToken: 'Lien invalide ou expiré',
        deleteLastDueDate: 'Vous ne pouvez pas supprimer la dernière échéance',
        importPays: 'Une erreur s\'est produite à l\'import des paies',
        'importPays-personalNumber-missing': `Les données d'import ne contiennent pas tout les matricules`,
        'importPays-RECORD_NOT_UNIQUE': `Les données d'import contiennent des matricules en double, les matricules doivent être uniques`,
        sendSimulation: 'Une erreur s\'est produite à l\'envoie de la simulation',
        sendSimulationPaySlipInvalidate: 'Une erreur s\'est produite à la validation du bulletin de salaire pour cette simulation, nous vous conseillons d\'augmenter le CH facturé ou le CH payé.',
        adminCommercialFormUnselectGeneralConditionTemplate: 'Choisissez un type de conditions général',
        adminCommercialFormUnUploadedFile: 'Importez vos conditions générales pour enregistrer',
        adminCommercialFormTauxReserveFinanciereUnset: 'Un taux de réserve financière est manquant',
        adminAdvFormBankAccountUnset: 'Un compte bancaire n\'est pas complet',
        adminAdvInvoiceNaming: 'Veuillez renseigner une nommenclature de facture, d\'avoir et un index de départ',
        adminAdvInvoiceNamingRefMissing: 'Veuillez ajouter le numéro de facture à la nomenclature',
        adminAdvFormBillingAccountUnset: 'Un compte d\'affacturage n\'est pas complet',
        adminAdvFormDateNotificationUnset: 'Veuillez sélectionner une régularité',
        adminCommercialFormUnSelectedContract: 'Choississez au moins un type de contrat',
        adminCommercialFormManagementRoofAmountUnset: 'La valeur du plafond est manquante',
        adminRhFormUnUploadedFile: 'Importez vos conditions générales pour enregistrer',
        adminRhFormCreditCardFeesUnset: 'Les frais de carte sont manquants',
        adminRhFormRestaurantsTicketsAmountUnset: 'Le nombre de tickets restaurants est manquant',
        adminRhFormSalaryGridUnvalid: 'Les lignes de la grille salariale doivent être complétées et ne pas contenir de doublons',
        contractNotImported: 'Vous devez importer un contrat pour valider',
        invalidHoraire: 'Nombre d\'heure invalide (1h à 24h max)',
        sendRestaurantTicketsRenunciationLetter: 'Une erreur s\'est produite lors de l\'envoie de la lettre de renonciation',
        paySlipDeclarationNotImport: `Le bulletin de paie de cette déclaration n'a pas été importé`,
        craClientNotAvailable: `Le CRA client n'a pas été importé`,
        craIndependentNotAvailable: `Le CRA du porté n'est pas disponible`,
        payDeclarationNotImport: `Les données de paie n'ont pas encore été importées`,
        'import-data-error': `Les données d'import sont invalides`,
        cancelContractSignature: `L'annulation de la signature en attente du contrat a échouer`,
        cancelSignatureConflict: `La signature est en cours de traitement, veuillez patienter avant de pouvoir l'annuler`,
        cancelSignatureUnauthorized: `Vous n'avez pas les droits pour annuler cette signature`,

        // mobile photo error
        cameraPhotoError: 'Une erreur s\'est produite lors de l\'utilisation de la camera',

        // directus-code-error
        INVALID_CREDENTIALS: 'Vos identifiants sont incorrects',

        // request errors
        defaultErrorKey: 'Ce champ est erroné', // no specific details
        clientSiretNotUnique: 'Ce siret est déjà utilisé',
        clientIdentificationNumberNotUnique: 'Ce numéro d\'identification est déjà utilisé',
        contactEmailNotUnique: 'Cet email est déjà utilisé',
        publicHolidaysGetError: 'Une erreur s\'est produite lors de la récupération des jours fériés',
        publicHolidayGetOneError: 'Une erreur s\'est produite lors de la récupération des jours fériés',
        publicHolidayDeleteError: 'Une erreur s\'est produite lors de la suppression des jours fériés',
        publicHolidaySaveError: 'Une erreur s\'est produite lors de la sauvegarde des jours fériés'
    },
    // SUCCESS
    success: {
        saveDocument: 'Document enregistré',
        deleteDocument: 'Document supprimé',
        saveSimulation: 'Simulation enregistrée',
        sendSimulation: 'Simulation envoyée',
        saveMission: 'Mission sauvegardée',
        saveSpecificMission: 'Mission sauvegardée {target}',
        savePay: 'Paie sauvegardée',
        saveDeclarePay: 'Déclaration de paie sauvegardée',
        declarePaieValidate: 'Déclaration de paie validée',
        saveDeclareFacturation: 'Déclaration de facturation sauvegardée',
        declareFacturationValidate: 'Déclaration de facturation validée',
        saveInvoice: 'Facture sauvegardée',
        saveNdf: 'Note de frais sauvegardée',
        saveProfil: 'Profil enregistré',
        importPays: 'Import réussi',
        forcePaysDeclaration: 'Paies déclarées',
        sendRestaurantTicketsRenunciationLetter: 'La lettre de renonciation a bien été envoyé',
        publicHolidaySave: `Les jours fériés de l'année {year} ont été sauvegardés`,
        publicHolidayDelete: `Les jours fériés de l'année {year} ont été supprimés`,
        publicHolidayCreate: `Les jours fériés de l'année {year} ont été créés`,
        cancelContractSignature: `L'annulation de la signature en attente du contrat a réussi`,
        reportDone: `Relance envoyée`
    },
    // WARNING
    warning: {
        invalidStackHoraire: 'Nombre d\'heures cumulées dépassé (1h à 24h max)',
        invalidStackDay: 'Nombre de journées cumulées dépassé (1 max)',
    },
    // INFO
    info: {},
};

export default translation;
